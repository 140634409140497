// /healthremind/queryPage
// /healthremind/addOrUpdateHealthRemind
// /healthremind/openAndClose
// /healthremind/deleteHealthRemind
// /healthremind/queryHealthRemindItem
import request from '@/utils/requestDoctor';

const baseUrl = 'https://scusapi.shanci.tech';

// 分页查询健康提醒列表
const queryPage = (query) =>
  request({
    url: baseUrl + '/healthremind/queryPage',
    method: 'post',
    data: query,
  });

const queryHealthRemindItem = (query) =>
  request({
    url: baseUrl + '/healthremind/queryHealthRemindItem',
    method: 'post',
    data: query,
  });

//添加健康提醒
const addOrUpdateHealthRemind = (query) =>
  request({
    url: baseUrl + '/healthremind/addOrUpdateHealthRemind',
    method: 'post',
    data: query,
  });
//删除健康提醒

const deleteHealthRemind = (query) =>
  request({
    url: baseUrl + '/healthremind/deleteHealthRemind',
    method: 'get',
    params: query,
  });

// 开启和关闭按钮
const openAndClose = (query) =>
  request({
    url: baseUrl + '/healthremind/openAndClose',
    method: 'get',
    params: query,
  });

//
const queryDetail = (query) =>
  request({
    url: baseUrl + '/healthremind/queryDetail?id=' + query.id,
    method: 'post',
    data: query,
  });

export {
  queryPage,
  queryHealthRemindItem,
  addOrUpdateHealthRemind,
  deleteHealthRemind,
  openAndClose,
  queryDetail,
};
